<template>
    <div>
     <topHead></topHead>
     <div class="recordWrap">
        <div class="title">预约考试</div>
        <el-form ref="form" :model="form" label-width="180px" class="myForm" v-if="show && ReserveTimeList.length == 0 ">
            <el-form-item label="请选择预约考试时间">
                <!-- <el-select v-model="reserveTime" clearable placeholder="请选择预约时间">
                    <el-option
                    v-for="item in ReserveTimeListOption"
                    :key="item.label"
                    :label="item.label"
                    :value="item.label">
                    </el-option>
                </el-select> -->
                <el-date-picker
                    v-model="reserveTime"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>
            
            <el-form-item style="margin-top:60px;">
                <el-button type="primary" @click="reserveExamine">立即预约</el-button>
                <!-- <el-button>取消</el-button> -->
            </el-form-item>
        </el-form>
        <div class="myTable">
            <el-table v-if="show && ReserveTimeList.length > 0 " 
            :data="ReserveTimeList"
            style="width: 100%">
            <el-table-column
            type="index"
            width="50">
            </el-table-column>
        <el-table-column
            prop="expertNo"
            label="专家帐号"
            width="180">
        </el-table-column>
        <el-table-column
            prop="examDate"
            label="已预约的考试时间"
            width="250">
        </el-table-column>
        <el-table-column
            prop="reserveTime"
            label="预约时间"
            width="200">
        </el-table-column>
        <el-table-column
            label="操作" >
            <template slot-scope="scope">
                <el-button type="warning" @click="reserveCancel(scope.row)">取消预约</el-button>
            </template>
        </el-table-column>
        </el-table>
        </div>
        
     </div>
         
    </div>
 </template> 
 <script>
 import netTool from '../../api/netTool';
     import foot  from '../../components/footer.vue';
     import topHead  from '../../components/topHead.vue';
 export default {
     
     data() {
         return {
             userType:localStorage.getItem('userType'),
             personName:'',
             tableData:[],
             total:0,
             params:{
                 pageNum: 1,
                 pageSize: 10,
                 platformId: this.$store.state.pfId,
                 expertUuid: localStorage.getItem('expertUuid'),
                 category:localStorage.getItem('userType') == '3' ? 1: 2
             },
             form:{
                date1:'',
                date2:''
             },
             ReserveTimeListOption:[], // 可预约的时间列表
             ReserveTimeList:[],  // 已预约的时间列表
             reserveTime:'',
             show:false,
             pickerOptions: {
                disabledDate: (time) => {
                        if (this.ReserveTimeListOption.length !== 0) {    
                            
                            // const one = 30 * 24 * 3600 * 1000
                            const minTime = new Date(this.ReserveTimeListOption[0]) 
                            const maxTime = new Date(this.ReserveTimeListOption[this.ReserveTimeListOption.length-1]) 
                            return time.getTime() < minTime - 8.64e7 || time.getTime() > maxTime
                        }
                    }
             }
         }
     },
     components:{
         foot,
         topHead
     },
     created(){
         this.userInfo = this.$store.state.userInfo;
         this.personName = localStorage.getItem('uuid')
         this.findReservePageList()
         this.findreserveDateList()
         document.title = "培训考试系统";
     },
     methods:{
        
         
         handleClose(done) {
             this.$confirm('确认关闭？')
             .then(_ => {
                 this.msgInfo = {};
                 done();
             })
             .catch(_ => {});
         },
         onSubmit(){},
         // 查询预约记录列表
         findReservePageList(){
             let params = {}
             netTool.findReservePageList(params).then(res => {
                console.log('res',res)
                this.ReserveTimeList = res.data.rows
                 this.show = true;
             })
         },
         // 预约考试
         reserveExamine(){
            this.$confirm('确认预约考试信息吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

                let params={
                    platformId: this.$store.state.pfId,
                    examDate:this.reserveTime,// 预约时间
                    category:localStorage.getItem('userType') == '3' ? 1: 2,
                }
                netTool.reserveExamine(params).then(res => {
                    console.log('res',res)
                    if(res.status == 0){
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        this.findReservePageList()
                    }else{
                        this.$notify.error({
                            title: '错误',
                            message: res.msg
                        });
                    }
                    
                    // this.loading = false;
                })
            }).catch(() => {});

         },
         // 取消预约考试
         reserveCancel(row){
            let params={
                id:row.id // 预约过的列表id
            }
            this.$confirm('确认取消预约考试信息吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => { 
                netTool.reserveCancel(params).then(res => {
                    console.log('res',res)
                    if(res.status == 0){
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        this.findReservePageList()
                    }else{
                        this.$notify.error({
                            title: '错误',
                            message: res.msg
                        });
                    }
                })
            })

         },
         // 查询可选择的预约考试日期
         findreserveDateList(id){
            let params={
                platformId: this.$store.state.pfId,
                category:localStorage.getItem('userType') == '3' ? 1: 2,
                type:3,
            }
            netTool.findDateList(params).then(res => {
                console.log('res',res)
                if(res.status ==  0){
                    this.ReserveTimeListOption = res.data
                    // this.ReserveTimeListOption = result.map((item,index) =>{
                    //     return {label:item,value:index}
                    // })
                }else{
                    this.$notify.error({
                        title: '错误',
                        message: res.msg
                    });
                }
                
                 
             })

         },
         handleCurrentChange(val){
             this.params.pageNum = val;
             this.getLearnList();
         },
     }
    
 }
 </script>
 <style scoped lang="scss">
 
 .recordWrap{
     width: 1200px;
     margin:0 auto;
     padding-top: 50px;
     box-sizing: border-box;
     min-height:calc(100vh - 260px);
 }
 .title{
    height:40px;
    line-height:40px;
    font-size:22px;
    text-align: center;
    font-weight:bold;
    margin-bottom:20px;
 }
 .myForm{
    width:500px;
    margin:0 auto;
    font-size:16px;
 }
 .myTable{
    width:800px;
    margin:0 auto;
    font-size:16px;
 }
 /deep/ .el-form-item__label{
    font-size:18px;
 }
 .infoUl{
     margin: 20px;
     li{
         line-height:30px;
         font-size: 14px;
     }
 }
 .el-dialog__header {
     background: #002a52;
     text-align: center;
     }
     .el-dialog__title {
     color: aliceblue;
     }
 
 
 
 </style>
     